import { EmptyError, forkJoin, lastValueFrom, Observable, of } from 'rxjs';
import { mergeMap, tap } from 'rxjs/operators';
import { AppStoreService } from './app-store.service';
import { AppService } from './app.service';
import { AuthService, User } from './auth.service';
import { Comment, CommentsService } from './comments.service';
import { Division, DivisionsService } from './divisions.service';
import { Effect, EffectsService } from './effects.service';
import { FileResource, FilesService } from './files.service';
import { ImmutableService } from './immutable.service';
import { Measure, MeasuresService } from './measures.service';
import { PatternsTracksService, PatternTrack } from './patterns-tracks.service';
import { Pattern, PatternsService } from './patterns.service';
import { Plugin, PluginsService } from './plugins.service';
import { ProgressService } from './progress.service';
import { ProjectStoreService } from './project-store.service';
import { Project, ProjectsService } from './projects.service';
import {
    AudioRegion,
    DrumRegion,
    MidiRegion,
    Region,
    RegionBase,
    RegionsService
} from './regions.service';
import {
    AudioTrack,
    DrumTrack,
    MidiTrack,
    Track,
    TracksService
} from './tracks.service';

import { inject, Injectable } from '@angular/core';
@Injectable({ providedIn: 'root' })
export class ProjectStateService {
    private nbTracksBeingCreated = 0;

    private get project(): Project {
        return this.projectStoreService.project;
    }

    private get shareToken() {
        return this.appStoreService.shareToken;
    }

    private appService = inject(AppService);
    private appStoreService = inject(AppStoreService);
    private commentsService = inject(CommentsService);
    private divisionsService = inject(DivisionsService);
    private effectsService = inject(EffectsService);
    private filesService = inject(FilesService);
    private immutableService = inject(ImmutableService);
    private measuresService = inject(MeasuresService);
    private patternsService = inject(PatternsService);
    private patternTracksService = inject(PatternsTracksService);
    private pluginsService = inject(PluginsService);
    private progressService = inject(ProgressService);
    private projectsService = inject(ProjectsService);
    private projectStoreService = inject(ProjectStoreService);
    private regionsService = inject(RegionsService);
    private tracksService = inject(TracksService);
    private usersService = inject(AuthService);

    loadProjectAndSubResources(
        projectId?: number | string,
        shouldShowProgress = true
    ) {
        const progressOpId =
            shouldShowProgress &&
            this.progressService.create({
                text: 'Loading project',
                max: 1,
                progress: 0,
                zone: 0
            });

        projectId =
            typeof projectId !== 'undefined' ? projectId : this.project.id;

        return this.projectsService.read(projectId, true).pipe(
            mergeMap((project) => this.projectLoaded(project, progressOpId)),
            tap(() => {
                this.appService.projectAndSubResourcesLoaded();
            })
        );
    }

    loadFiles(shouldShowProgress = true) {
        const progressOpId =
            shouldShowProgress &&
            this.progressService.create({
                text: 'Loading files',
                max: 1,
                progress: 0,
                zone: 0
            });

        return this.filesService
            .readAll(this.project.id, true)
            .pipe(mergeMap((files) => this.filesLoaded(files, progressOpId)));
    }

    loadTracks(loadSubResources = true, shouldShowProgress = true) {
        const progressOpId =
            shouldShowProgress &&
            this.progressService.create({
                text: 'Loading tracks',
                max: 1,
                progress: 0,
                zone: 0
            });

        return this.tracksService
            .readAll(this.project.id)
            .pipe(
                mergeMap((tracks: Track[]) =>
                    this.tracksLoaded(tracks, progressOpId, loadSubResources)
                )
            );
    }

    loadEffects(
        track: AudioTrack | MidiTrack | DrumTrack,
        shouldShowProgress = true
    ) {
        const progressOpId =
            shouldShowProgress &&
            this.progressService.create({
                text: 'Loading effects',
                max: 1,
                progress: 0,
                zone: 0
            });

        return this.effectsService
            .readAll(this.project.id, track.id)
            .pipe(
                tap((effects) =>
                    this.effectsLoaded(track, effects, progressOpId)
                )
            );
    }

    loadRegionsAndSubResources(
        track: AudioTrack | MidiTrack | DrumTrack,
        shouldShowProgress = true
    ) {
        const progressOpId =
            shouldShowProgress &&
            this.progressService.create({
                text: 'Loading regions',
                max: 1,
                progress: 0,
                zone: 0
            });

        return this.regionsService
            .readAll(this.project.id, track.id)
            .pipe(
                mergeMap((regions) =>
                    this.regionsLoaded(track, regions, progressOpId)
                )
            );
    }

    loadPatternsAndSubResources(track: DrumTrack, shouldShowProgress = true) {
        const progressOpId =
            shouldShowProgress &&
            this.progressService.create({
                text: 'Loading patterns',
                max: 1,
                progress: 0,
                zone: 0
            });

        return this.patternsService
            .readAll(this.project.id, track.id)
            .pipe(
                mergeMap((patterns: Pattern[]) =>
                    this.patternsLoaded(track, patterns, progressOpId)
                )
            );
    }

    loadMeasures(
        track: DrumTrack,
        pattern: Pattern,
        shouldShowProgress = true
    ) {
        const progressOpId =
            shouldShowProgress &&
            this.progressService.create({
                text: 'Loading measures',
                max: 1,
                progress: 0,
                zone: 0
            });

        return this.measuresService
            .readAll(this.project.id, pattern.trackId, pattern.id)
            .pipe(
                tap((measures) =>
                    this.measuresLoaded(track, pattern, measures, progressOpId)
                )
            );
    }

    private loadComments(
        track: Track,
        region: RegionBase,
        shouldShowProgress = true
    ) {
        const progressOpId =
            shouldShowProgress &&
            this.progressService.create({
                text: 'Loading comments',
                max: 1,
                progress: 0,
                zone: 0
            });

        return this.commentsService
            .readAll(this.project.id, region.trackId, region.id)
            .pipe(
                mergeMap((comments) =>
                    this.commentsLoaded(track, region, comments, progressOpId)
                )
            );
    }

    loadPatternTracksAndSubResources(
        track: DrumTrack,
        shouldShowProgress = true
    ) {
        const progressOpId =
            shouldShowProgress &&
            this.progressService.create({
                text: 'Loading pattern tracks',
                max: 1,
                progress: 0,
                zone: 0
            });

        return this.patternTracksService
            .readAll(this.project.id, track.id)
            .pipe(
                mergeMap((patternTracks: PatternTrack[]) =>
                    this.patternTracksLoaded(track, patternTracks, progressOpId)
                )
            );
    }

    loadDivisions(
        track: Track,
        patternTrack: PatternTrack,
        shouldShowProgress = true
    ) {
        const progressOpId =
            shouldShowProgress &&
            this.progressService.create({
                text: 'Loading divisons',
                max: 1,
                progress: 0,
                zone: 0
            });

        return this.divisionsService
            .readAll(this.project.id, track.id, patternTrack.id)
            .pipe(
                tap((divisions) =>
                    this.divisionsLoaded(
                        track,
                        patternTrack,
                        divisions,
                        progressOpId
                    )
                )
            );
    }

    loadProjectUser(shouldShowProgress = true) {
        const progressOpId =
            shouldShowProgress &&
            this.progressService.create({
                text: 'Loading user information',
                max: 1,
                progress: 0,
                zone: 0
            });

        return this.usersService
            .read(this.project.userId)
            .pipe(tap((user) => this.projectUserLoaded(user, progressOpId)));
    }

    loadFileUser(fileResource: FileResource, shouldShowProgress = true) {
        const progressOpId =
            shouldShowProgress &&
            this.progressService.create({
                text: 'Loading user information',
                max: 1,
                progress: 0,
                zone: 0
            });

        return this.usersService
            .read(fileResource.userId)
            .pipe(
                tap((user) =>
                    this.fileUserLoaded(fileResource, user, progressOpId)
                )
            );
    }

    loadCommentUser(
        track: Track,
        region: RegionBase,
        comment: Comment,
        shouldShowProgress = true
    ) {
        const progressOpId =
            shouldShowProgress &&
            this.progressService.create({
                text: 'Loading user information',
                max: 1,
                progress: 0,
                zone: 0
            });

        return this.usersService
            .read(comment.userId)
            .pipe(
                tap((user) =>
                    this.commentUserLoaded(
                        track,
                        region,
                        comment,
                        user,
                        progressOpId
                    )
                )
            );
    }

    // LOADED
    private projectLoaded(
        loadedProject: Project,
        progressOpId: number | false
    ) {
        if (this.project) {
            this.projectUpdated(loadedProject);
        } else {
            if (this.shareToken) {
                loadedProject.id = this.shareToken;
            }

            this.projectStoreService.project = this.immutableService.create(
                loadedProject,
                () => {
                    // Do nothing
                }
            );
        }

        progressOpId && this.progressService.update(progressOpId, 1);

        const observables = [
            this.loadTracks(true, !!progressOpId),
            this.loadFiles(!!progressOpId),
            this.loadProjectUser(!!progressOpId)
        ];

        return forkJoin(observables);
    }

    private filesLoaded(files: FileResource[], progressOpId: number | false) {
        progressOpId && this.progressService.update(progressOpId, 1);

        this.projectStoreService.mergeRefresedCollectionToCurrentCollectionImmutably(
            [],
            'files',
            files
        );

        return this.refreshCollectionSubResources(
            [],
            files,
            this.loadFileUser,
            !!progressOpId
        );
    }

    private tracksLoaded(
        loadedTracks: Track[],
        progressOpId: number | false,
        loadSubResources: boolean
    ) {
        this.projectStoreService.mergeRefresedCollectionToCurrentCollectionImmutably(
            [],
            'tracks',
            loadedTracks
        );

        progressOpId && this.progressService.update(progressOpId, 1);

        if (loadSubResources) {
            return loadTracksSubResources.call(this, !!progressOpId);
        } else {
            return of([]);
        }

        function loadTracksSubResources(
            this: ProjectStateService,
            shouldShowProgress = true
        ) {
            const drumTracks = this.project.tracks.filter(
                (t) => t.type === 'drum'
            );

            const observables = [];

            observables.push(
                this.refreshCollectionSubResources(
                    [],
                    this.project.tracks,
                    this.loadRegionsAndSubResources,
                    shouldShowProgress
                )
            );

            observables.push(
                this.refreshCollectionSubResources(
                    [],
                    this.project.tracks,
                    this.loadEffects,
                    shouldShowProgress
                )
            );

            observables.push(
                this.refreshCollectionSubResources(
                    [],
                    drumTracks,
                    this.loadPatternsAndSubResources,
                    shouldShowProgress
                )
            );

            observables.push(
                this.refreshCollectionSubResources(
                    [],
                    drumTracks,
                    this.loadPatternTracksAndSubResources,
                    shouldShowProgress
                )
            );

            return forkJoin(observables);
        }
    }

    private regionsLoaded(
        track: AudioTrack | MidiTrack | DrumTrack,
        loadedRegions: AudioRegion[] | MidiRegion[] | DrumRegion[],
        progressOpId: number | false
    ) {
        track =
            this.projectStoreService.mergeRefresedCollectionToCurrentCollectionImmutably(
                [{ collectionName: 'tracks', itemId: track.id }],
                'regions',
                loadedRegions
            );

        progressOpId && this.progressService.update(progressOpId, 1);

        if (!this.shareToken) {
            return this.refreshCollectionSubResources(
                [track],
                loadedRegions,
                this.loadComments,
                !!progressOpId
            );
        } else {
            return of([]);
        }
    }

    private patternsLoaded(
        track: DrumTrack,
        loadedPatterns: Pattern[],
        progressOpId: number | false
    ) {
        track =
            this.projectStoreService.mergeRefresedCollectionToCurrentCollectionImmutably(
                [{ collectionName: 'tracks', itemId: track.id }],
                'patterns',
                loadedPatterns
            );

        progressOpId && this.progressService.update(progressOpId, 1);

        return this.refreshCollectionSubResources(
            [track],
            track.patterns,
            this.loadMeasures,
            !!progressOpId
        );
    }

    private commentsLoaded(
        track: Track,
        region: RegionBase,
        loadedComments: Comment[],
        progressOpId: number | false
    ) {
        region =
            this.projectStoreService.mergeRefresedCollectionToCurrentCollectionImmutably(
                [
                    { collectionName: 'tracks', itemId: track.id },
                    { collectionName: 'regions', itemId: region.id }
                ],
                'comments',
                loadedComments
            );

        progressOpId && this.progressService.update(progressOpId, 1);

        return this.refreshCollectionSubResources(
            [track, region],
            loadedComments,
            this.loadCommentUser,
            !!progressOpId
        );
    }

    private patternTracksLoaded(
        track: DrumTrack,
        loadedPatternTracks: PatternTrack[],
        progressOpId: number | false
    ) {
        track =
            this.projectStoreService.mergeRefresedCollectionToCurrentCollectionImmutably(
                [{ collectionName: 'tracks', itemId: track.id }],
                'patternTracks',
                loadedPatternTracks
            );

        progressOpId && this.progressService.update(progressOpId, 1);

        return this.refreshCollectionSubResources(
            [track],
            loadedPatternTracks,
            this.loadDivisions,
            !!progressOpId
        );
    }

    private measuresLoaded(
        track: DrumTrack,
        pattern: Pattern,
        loadedMeasures: Measure[],
        progressOpId: number | false
    ) {
        pattern =
            this.projectStoreService.mergeRefresedCollectionToCurrentCollectionImmutably(
                [
                    { collectionName: 'tracks', itemId: track.id },
                    { collectionName: 'patterns', itemId: pattern.id }
                ],
                'measures',
                loadedMeasures
            );

        progressOpId && this.progressService.update(progressOpId, 1);
    }

    private effectsLoaded(
        track: AudioTrack | MidiTrack | DrumTrack,
        loadedEffects: Effect[],
        progressOpId: number | false
    ) {
        track =
            this.projectStoreService.mergeRefresedCollectionToCurrentCollectionImmutably(
                [{ collectionName: 'tracks', itemId: track.id }],
                'effects',
                loadedEffects
            );

        progressOpId && this.progressService.update(progressOpId, 1);
    }

    private pluginsLoaded(
        track: MidiTrack,
        loadedPlugins: Plugin[],
        progressOpId: number | false
    ) {
        track =
            this.projectStoreService.mergeRefresedCollectionToCurrentCollectionImmutably<MidiTrack>(
                [{ collectionName: 'tracks', itemId: track.id }],
                'plugins',
                loadedPlugins
            );

        progressOpId && this.progressService.update(progressOpId, 1);
    }

    private divisionsLoaded(
        track: Track,
        patternTrack: PatternTrack,
        loadedDivisions: Division[],
        progressOpId: number | false
    ) {
        this.projectStoreService.mergeRefresedCollectionToCurrentCollectionImmutably(
            [
                { collectionName: 'tracks', itemId: track.id },
                { collectionName: 'patternTracks', itemId: patternTrack.id }
            ],
            'divisions',
            loadedDivisions
        );

        progressOpId && this.progressService.update(progressOpId, 1);
    }

    private projectUserLoaded(loadedUser: User, progressOpId: number | false) {
        this.projectStoreService.setResourceToParentResourceImmutably(
            [],
            'user',
            loadedUser
        );

        progressOpId && this.progressService.update(progressOpId, 1);
    }

    private fileUserLoaded(
        file: FileResource,
        loadedUser: User,
        progressOpId: number | false
    ) {
        this.projectStoreService.setResourceToParentResourceImmutably(
            [{ collectionName: 'files', itemId: file.id }],
            'user',
            loadedUser
        );

        progressOpId && this.progressService.update(progressOpId, 1);
    }

    private commentUserLoaded(
        track: Track,
        region: RegionBase,
        comment: Comment,
        loadedUser: User,
        progressOpId: number | false
    ) {
        this.projectStoreService.setResourceToParentResourceImmutably(
            [
                { collectionName: 'tracks', itemId: track.id },
                { collectionName: 'regions', itemId: region.id },
                { collectionName: 'comments', itemId: comment.id }
            ],
            'user',
            loadedUser
        );

        progressOpId && this.progressService.update(progressOpId, 1);
    }

    private drumTrackResourcesLoaded(
        loadedDrumTrack: DrumTrack,
        progressOpId?: number
    ) {
        typeof progressOpId === 'number' &&
            this.progressService.update(progressOpId, 1);

        return of(loadedDrumTrack);
    }

    // CREATES
    createTrack(
        track: Partial<AudioTrack | MidiTrack | DrumTrack> & {
            type: 'audio' | 'midi' | 'drum';
        },
        shouldShowProgress = true
    ) {
        let progressOpId: number | undefined;

        if (shouldShowProgress) {
            progressOpId = this.progressService.create({
                text: 'Creating track',
                max: 1,
                progress: 0,
                zone: 0
            });
        }

        this.nbTracksBeingCreated++;

        if (!track.name) {
            const typeToNameMap = {
                audio: 'Audio',
                drum: 'Drum',
                midi: 'MIDI'
            };
            const nbCurrentTracksOfType = this.project.tracks.filter(
                (t: Track) => t.type === track.type
            ).length;
            const trackNb = nbCurrentTracksOfType + this.nbTracksBeingCreated;
            track.name = `${typeToNameMap[track.type]} #${trackNb}`;
        }

        return this.tracksService
            .create(this.project.id, track)
            .pipe(mergeMap((t) => this.trackCreated(t, progressOpId)));
    }

    createFile(
        s3Key: string,
        isDrumSample?: boolean,
        fileId?: number,
        shouldShowProgress = true
    ) {
        return this.filesService
            .create({
                projectId: this.project.id,
                s3Key,
                fileId,
                isDrumSample
            })
            .pipe(
                mergeMap((file: FileResource) => {
                    return this.fileCreated(file, shouldShowProgress);
                })
            );
    }

    createRegion(
        track: Track,
        newRegion: Partial<Region>,
        pushRegion = true,
        ab?: ArrayBuffer
    ) {
        return this.regionsService
            .create(this.project.id, track.id, newRegion, ab)
            .pipe(
                mergeMap((region) =>
                    this.regionCreated(track.id, region, pushRegion)
                )
            );
    }

    async duplicateRegion(
        trackId: number,
        sourceRegionId: number,
        newRegion: Region
    ) {
        const duplicatedRegion = await lastValueFrom(
            this.regionsService.duplicate(
                this.project.id,
                trackId,
                sourceRegionId,
                newRegion
            )
        );

        this.regionCreated(trackId, duplicatedRegion, true);
    }

    createPlugin(track: MidiTrack, newPlugin: Partial<Plugin>) {
        if (!newPlugin.trackId) throw new Error('FF');

        return this.pluginsService
            .create(this.project.id, newPlugin.trackId, newPlugin)
            .pipe(tap((plugin) => this.pluginCreated(track, plugin)));
    }

    createComment(
        track: Track,
        region: RegionBase,
        newComment: Partial<Comment>
    ) {
        return this.commentsService
            .create(this.project.id, track.id, region.id, newComment)
            .pipe(
                mergeMap((comment) =>
                    this.commentCreated(track, region, comment)
                )
            );
    }

    createEffect(trackId: number, newEffect: Partial<Effect>) {
        return this.effectsService
            .create(this.project.id, trackId, newEffect)
            .pipe(mergeMap((e) => this.effectCreated(trackId, e)));
    }

    // CREATED
    private trackCreated(createdTrack: Track, progressOpId?: number) {
        this.nbTracksBeingCreated -= 1;
        createdTrack.regions = [];
        createdTrack.effects = [];

        if (createdTrack.type === 'midi') {
            createdTrack.plugins = [];
        }

        if (createdTrack.type === 'drum') {
            createdTrack.patterns = [];
        }

        this.projectStoreService.pushResourceToCurrentCollectionImmutably(
            [],
            'tracks',
            createdTrack
        );

        if (createdTrack.type === 'drum') {
            const observables = [
                this.loadPatternsAndSubResources(createdTrack),
                this.loadPatternTracksAndSubResources(createdTrack),
                this.loadRegionsAndSubResources(createdTrack)
            ];

            return forkJoin(observables).pipe(
                mergeMap(() => {
                    // Need to fetch the most recent track object, because it has been updated multiple times when loading the sub resources
                    const track =
                        this.projectStoreService.getResource<DrumTrack>(
                            this.project,
                            [
                                {
                                    collectionName: 'tracks',
                                    itemId: createdTrack.id
                                }
                            ]
                        );

                    return this.drumTrackResourcesLoaded(track, progressOpId);
                })
            );
        } else {
            progressOpId && this.progressService.update(progressOpId, 1);

            return of(createdTrack);
        }
    }

    private regionCreated(
        trackId: number,
        createdRegion: RegionBase,
        pushRegion: boolean
    ) {
        createdRegion.comments = [];

        if (pushRegion) {
            this.projectStoreService.pushResourceToCurrentCollectionImmutably(
                [{ collectionName: 'tracks', itemId: trackId }],
                'regions',
                createdRegion
            );
        }

        return of(createdRegion);
    }

    private fileCreated(file: FileResource, shouldShowProgress = true) {
        this.projectStoreService.pushResourceToCurrentCollectionImmutably(
            [],
            'files',
            file
        );

        this.loadFileUser(file, shouldShowProgress).subscribe();

        return of(file);
    }

    private pluginCreated(track: MidiTrack, createdPlugin: Plugin) {
        this.projectStoreService.pushResourceToCurrentCollectionImmutably(
            [{ collectionName: 'tracks', itemId: track.id }],
            'plugins',
            createdPlugin
        );
    }

    private commentCreated(track: Track, region: RegionBase, comment: Comment) {
        this.projectStoreService.pushResourceToCurrentCollectionImmutably(
            [
                { collectionName: 'tracks', itemId: track.id },
                { collectionName: 'regions', itemId: region.id }
            ],
            'comments',
            comment
        );

        return this.loadCommentUser(track, region, comment);
    }

    private effectCreated(trackId: number, createdEffect: Effect) {
        this.projectStoreService.pushResourceToCurrentCollectionImmutably(
            [{ collectionName: 'tracks', itemId: trackId }],
            'effects',
            createdEffect
        );

        return of(createdEffect);
    }

    // UPDATES
    updateProject() {
        return this.projectsService
            .update(this.project)
            .pipe(tap((project) => this.projectUpdated(project)));
    }

    async updateProjectPromise() {
        try {
            return await lastValueFrom(this.updateProject());
        } catch (e: unknown) {
            // Returning EMPTY in the CustomHttpInterceptorService when there is an error throws an error when using lastValueFrom, because it expects that one value is emitted
            if (!(e instanceof EmptyError)) {
                throw e;
            }
        }
    }

    updateFile(file: FileResource) {
        return this.filesService
            .update(file)
            .pipe(tap(() => this.fileUpdated(file)));
    }

    private fileUpdated(updatedFile: FileResource) {
        this.projectStoreService.assignToResourceImmutably(
            [
                {
                    collectionName: 'files',
                    itemId: updatedFile.id
                }
            ],
            {
                name: updatedFile.name
            }
        );
    }

    // UPDATED
    private projectUpdated(updatedProject: Project) {
        this.projectStoreService.assignToResourceImmutably([], updatedProject);
    }

    // DELETES
    destroyFile(file: FileResource) {
        return this.filesService.delete(this.project.id, file.id);
    }

    destroyRegion(track: Track, region: RegionBase) {
        return this.regionsService
            .delete(this.project.id, track.id, region.id)
            .pipe(tap(() => this.regionDeleted(track, region)));
    }

    destroyPlugin(track: MidiTrack, plugin: Plugin) {
        return this.pluginsService
            .destroy(this.project.id, track.id, plugin.id)
            .pipe(tap(() => this.pluginDeleted(track, plugin)));
    }

    destroyComment(track: Track, region: RegionBase, comment: Comment) {
        return this.commentsService
            .delete(this.project.id, track.id, region.id, comment.id)
            .pipe(tap(() => this.commentDeleted(track, region, comment)));
    }

    // DESTROYED
    private regionDeleted(track: Track, deletedRegion: RegionBase) {
        if (this.projectStoreService.selectedRegionId === deletedRegion.id) {
            delete this.projectStoreService.selectedRegionId;
        }

        this.projectStoreService.removeResourceFromCurrentCollectionImmutably(
            [{ collectionName: 'tracks', itemId: track.id }],
            'regions',
            deletedRegion
        );

        this.appService.regionDeleted(deletedRegion);
    }

    private pluginDeleted(track: MidiTrack, deletedPlugin: Plugin) {
        this.projectStoreService.removeResourceFromCurrentCollectionImmutably(
            [{ collectionName: 'tracks', itemId: track.id }],
            'plugins',
            deletedPlugin
        );
    }

    private commentDeleted(track: Track, region: RegionBase, comment: Comment) {
        this.projectStoreService.removeResourceFromCurrentCollectionImmutably(
            [
                { collectionName: 'tracks', itemId: track.id },
                { collectionName: 'regions', itemId: region.id }
            ],
            'comments',
            comment
        );
    }

    // UTILS
    private refreshCollectionSubResources(
        parentResources: unknown[],
        currentCollection: unknown[],
        refreshCb: any, // eslint-disable-line @typescript-eslint/no-explicit-any
        shouldShowProgress = true
    ) {
        const observables = currentCollection.map(
            (item: unknown): Observable<unknown> => {
                return refreshCb.call(
                    this,
                    ...parentResources,
                    item,
                    shouldShowProgress
                );
            }
        );

        return observables.length ? forkJoin(observables) : of(null);
    }
}
